<template>
  <div flat class="overflow-hidden">
    <v-app-bar elevation="1" absolute color="white" dark scroll-target="#scrolling-techniques-8">
      <v-btn small icon color="secondary" @click="IrALista()">
        <v-icon>{{ icons.mdiArrowLeftThick }}</v-icon>
      </v-btn>

      <v-toolbar-title class="black--text">Reporte {{ datos.id <= 0 ? 'Nuevo' : datos.id }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn small color="secondary" @click="limpiar()" class="me-3"> Limpiar </v-btn>
      <v-btn small color="primary" class="me-2" @click="guardar()"> Guardar </v-btn>
    </v-app-bar>
    <v-sheet id="scrolling-techniques-8" class="mx-0 px-0 overflow-y-auto" max-height="calc(100vh - 76px)">
      <div style="margin-top: 70px">
        <v-form ref="form" lazy-validation>
          <v-card-text class="d-flex">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :rules="reglas.requerido"
                  hide-details="auto"
                  v-model="datos.nombre"
                  dense
                  outlined
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :rules="reglas.requerido"
                  hide-details="auto"
                  v-model="datos.descripcion"
                  dense
                  outlined
                  label="Descripcion"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  :rules="reglas.requerido"
                  hide-details="auto"
                  v-model="datos.ordinal"
                  dense
                  outlined
                  label="ordinal"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :rules="reglas.requerido"
                  item-text="descripcion"
                  item-value="id"
                  hide-details="auto"
                  v-model="datos.id_categoria"
                  dense
                  outlined
                  :items="categorias"
                  label="tipo_reporte"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  readonly
                  hide-details="auto"
                  v-model="datos.tipo_reporte"
                  dense
                  outlined
                  :items="[
                    { value: 1, text: 'MODULO' },
                    { value: 2, text: 'DINAMICO' },
                  ]"
                  label="tipo_reporte"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-card @click="dialog = true" style="width: 60px">
                  <v-icon size="60">{{ datos.imgicon }}</v-icon>
                </v-card>
                <v-switch v-model="datos.ind_activo" :label="`Ind Activo`"></v-switch>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  :ripple="false"
                  rows="9"
                  name="input-7-1"
                  value=""
                  outlined
                  v-model="datos.querys"
                  label="Query"
                  @input="onInput"
                  ref="textarea"
                  spellcheck="false"
                  :rules="reglas.requerido"
                  hide-details="auto"
                >
                </v-textarea>

                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-y
                  :position-x="menuX"
                  :position-y="menuY"
                  absolute
                >
                  <v-list style="max-height: 200px; overflow-y: auto">
                    <v-list-item
                      v-for="(item, index) in filteredOptions"
                      :key="index"
                      @click="selectOption(`${item.table_schema}.${item.table_name}`)"
                    >
                      <v-list-item-title>{{ `${item.table_schema}.${item.table_name}` }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-subtitle>
          cabeceras
          <v-btn class="ml-2" icon small @click="abrirCabeceras()">
            <v-icon color="primary">{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </v-card-subtitle>
        <small v-if="!(cabeceras_value.length > 0)" class="ml-2 error--text">Debe agregar por lo menos 1 campo</small>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th width="30%" class="text-left">Text</th>
                <th width="30%" class="text-left">value</th>
                <th  width="20%" class="text-left">format</th>
 
                <th width="20%" class="text-right">Accion</th>
              </tr>
            </thead>
          </v-simple-table>

          <draggable v-model="cabeceras_value">
            <transition-group>
              <v-simple-table v-for="item in cabeceras_value" :key="item.key">
                <tbody>
                  <tr>
                    <td width="30%">{{ item.text }}</td>
                    <td width="30%">{{ item.value }}</td>
                    <td width="20%">{{ item.format }}</td>
                    <td width="20%" class="text-right">
                      <v-btn class="ml-2" icon small @click="abrirCabeceras(item)">
                        <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
                      </v-btn>
                      <v-btn class="ml-2" icon small @click="eliminarCabeceras(item)">
                        <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </transition-group>
          </draggable>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text> </v-card-text>
        <v-card-subtitle>
          Filtros
          <v-btn class="ml-2" icon small @click="abrirFiltros()">
            <v-icon color="primary">{{ icons.mdiPlus }}</v-icon>
          </v-btn></v-card-subtitle
        >
        <v-divider></v-divider>
        <small v-if="!(filtros_value.length > 0)" class="ml-2 warning--text">No ah agregado algun filtro</small>

        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">id</th>
                  <th class="text-left">tipo</th>
                  <th class="text-left">default</th>
                  <th class="text-left">label</th>
                  <th class="text-left">filtros</th>
                  <th class="text-left">add</th>
                  <th class="text-left">dense</th>
                  <th class="text-left">clearable</th>
                  <th class="text-left">rules</th>
                  <th class="text-left">disabled</th>
                  <th class="text-left">icon</th>
                  <th class="text-left">outlined</th>
                  <th class="text-left">orden</th>
                  <th class="text-left">accion</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filtros_value" :key="index">
                  <td>{{ item.id }}</td>
                  <td>{{ item.tipo }}</td>
                  <td>{{ item.default }}</td>
                  <td>{{ item.label }}</td>
                  <td>{{ item.filtros }}</td>
                  <td>{{ item.add }}</td>
                  <td>{{ item.dense }}</td>
                  <td>{{ item.clearable }}</td>
                  <td>{{ item.rules }}</td>
                  <td>{{ item.disabled }}</td>
                  <td>{{ item.icon }}</td>
                  <td>{{ item.outlined }}</td>
                  <td>{{ item.order }}</td>
                  <td>
                    <v-btn class="ml-2" icon small @click="abrirFiltros(item)">
                      <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
                    </v-btn>
                    <v-btn class="ml-2" icon small @click="eliminarFiltros(item)">
                      <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <tbody></tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
      </div>
    </v-sheet>

    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Iconos </v-card-title>

        <v-card-text v-if="dialog">
          <v-row>
            <v-col v-for="(items, index) in iconos" :key="index">
              <v-card style="width: 60px" class="text-center" @click="seleccionarIcon(items.icon.data)">
                <v-icon size="60">{{ items.icon.data }}</v-icon>
                <small>{{ items.icon.name }}</small>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false"> cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCabecera" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> cabecera </v-card-title>

        <v-card-text>
          <v-form ref="formCabecera" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :rules="reglas.requerido"
                  hide-details="auto"
                  v-model="cabecera.text"
                  dense
                  outlined
                  label="Text"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :rules="reglas.requerido"
                  hide-details="auto"
                  v-model="cabecera.value"
                  dense
                  outlined
                  label="Value"
                ></v-text-field>
              </v-col>
               
              <v-col cols="12">
                <v-textarea
                  hide-details="auto"
                  v-model="cabecera.format"
                  dense
                  outlined
                  label="Format"
                  spellcheck="false"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialogCabecera = false"> cerrar </v-btn>
          <v-btn color="primary" @click="guardarCabecera()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFiltros" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> filtros </v-card-title>
        {{ filtro }}
        <v-card-text>
          <v-form ref="formFiltro" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  hide-details="auto"
                  v-model="filtro.id"
                  dense
                  outlined
                  label="Text"
                  :rules="reglas.requerido"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  hide-details="auto"
                  v-model="filtro.tipo"
                  dense
                  outlined
                  :items="tiposFiltros"
                  label="tipo"
                  :rules="reglas.requerido"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-details="auto"
                  v-model="filtro.default"
                  dense
                  outlined
                  label="Default"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-details="auto"
                  v-model="filtro.label"
                  dense
                  outlined
                  label="label"
                  :rules="reglas.requerido"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-details="auto"
                  v-model="filtro.order"
                  dense
                  outlined
                  label="ordinal"
                  :rules="reglas.requerido"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="filtro.add" :label="`add`"></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="filtro.dense" :label="`dense`"></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="filtro.clearable" :label="`clearable`"></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="filtro.disabled" :label="`disabled`"></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="filtro.icon" :label="`icon`"></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="filtro.outlined" :label="`outlined`"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  hide-details="auto"
                  v-model="filtro.filtros"
                  dense
                  outlined
                  label="filtros"
                  spellcheck="false"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  hide-details="auto"
                  v-model="filtro.rules"
                  dense
                  outlined
                  label="rules"
                  spellcheck="false"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  hide-details="auto"
                  v-model="filtro.items"
                  dense
                  outlined
                  label="items"
                  spellcheck="false"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialogFiltros = false"> cerrar </v-btn>
          <v-btn color="primary" @click="guardarFiltros()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import funciones from '@/funciones/funciones'

import {
  mdiPackageVariantClosed,
  mdiInformation,
  mdiDelete,
  mdiPencil,
  mdiArrowUpThick,
  mdiEqual,
  mdiArrowDownThick,
  mdiCached,
  mdiArrowLeftThick,
  mdiPlus,
  mdiTurkey,
} from '@mdi/js'
import CompraServices from '@/api/servicios/inventario/CompraServices'

import MaestroService from '@/api/servicios/MaestroService'
import config from '@/api/config'
import router from '@/router'
export default {
  components: {
    draggable,
  },

  methods: {
    onInput(event) {
      const cursorPosition = this.$refs.textarea.$el.querySelector('textarea').selectionStart
      const textBeforeCursor = this.datos.querys.slice(0, cursorPosition)
      const atIndex = textBeforeCursor.lastIndexOf('@')

      if (atIndex !== -1) {
        this.filteredOptions = this.tablas.filter(option =>
          option.table_name.toLowerCase().includes(textBeforeCursor.slice(atIndex + 1).toLowerCase()),
        )

        if (this.filteredOptions.length > 0) {
          this.menu = true
          this.$nextTick(() => {
            const textarea = this.$refs.textarea.$el.querySelector('textarea')
            const { top, left } = textarea.getBoundingClientRect()
            this.menuX = left
            this.menuY = top + textarea.offsetHeight
          })
        } else {
          this.menu = false
        }
      } else {
        this.menu = false
      }
    },
    selectOption(option) {
      const cursorPosition = this.$refs.textarea.$el.querySelector('textarea').selectionStart
      const textBeforeCursor = this.datos.querys.slice(0, cursorPosition)
      const atIndex = textBeforeCursor.lastIndexOf('@')
      this.datos.querys = this.datos.querys.slice(0, atIndex) + option + this.datos.querys.slice(cursorPosition)
      this.menu = false
    },
  },
  setup(props, context) {
    const IrALista = () => {
      context.emit('IrALista', {})
    }
    const seleccionarIcon = icon => {
      datos.value.imgicon = icon
      dialog.value = false
    }

    const tiposFiltros = [
      'SelectPuestoMulti',
      'SelectPuesto',
      'SelectDosFechas',
      'SelectAlmacen',
      'SelectEstado',
      'SelectMotivo',
      'SelectProducto',
      'SelectGrupoNegocio',
      'SelectFecha',
      'SelectCategoria',
      'SelectSubCategoria',
      'SelectEmpleado',
      'SelectCaja',
      'SelectProveedor',
      'Text',
      'Select',
    ]
    const generarIdUnico = () => {
      const timestamp = new Date().getTime()
      const randomValue = Math.floor(Math.random() * 1000000) // Puedes ajustar el rango según tus necesidades
      return `${timestamp}-${randomValue}`
    }
    const dialogCabecera = ref(false)
    const dialogFiltros = ref(false)

    const dialog = ref(false)
    const iconos = require('@/assets/icons/icons.json')
    const limpiar = () => {
      router.push({ name: 'administracion-archivo-reportes', params: { vista: 0 } })
    }
    const datosInit = {
      id: 0,
      id_accion_modulo: 32,
      ind_activo: true,
      ordinal: null,
      nombre: '',
      descripcion: '',
      url: null,
      imgicon:
        'M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z',
      id_categoria: null,
      tipo_reporte: 2,
      filtros: '[]',
      cabeceras: '[]',
      querys: '',
      categoria: null,
    }
    const filtro = ref({
      id: 'id',
      tipo: 'Text',
      value: '',
      default: '',
      add: false,
      dense: true,
      clearable: true,
      label: 'label',
      rules: '[]',
      disabled: false,
      filtros: '',
      filtrosValue: null,
      icon: false,
      outlined: true,
      items: '',
    })

    const cabecera = ref({
      text: 'Id',
      value: 'id',
      format: {
        small: true,
      },
    })
    const tablasSelects = ref([])
    const datos = ref({
      id: -1,
    })

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }

    const eliminarCabeceras = item => {
      cabeceras_value.value = cabeceras_value.value.filter(it => it.key != item.key)
    }
    const eliminarFiltros = item => {
      filtros_value.value = filtros_value.value.filter(it => it.id != item.id)
    }

    const tablas = ref([])
    onBeforeMount(() => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      cargarTablas()
      cargarCategorias()
    })
    const cargarTablas = () => {
      MaestroService.ListarTablas()
        .then(response => {
          tablas.value = response.data
        })
        .catch(error => {})
        .finally(() => {})
    }
    const categorias = ref([])
    const cargarCategorias = async (id = -1) => {
      try {
        await MaestroService.reportesListar({ id, ind_activo: 'all' })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (id != -1) {
                datos.value = response.data.datos[0]
              }
              categorias.value = response.data.categorias
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {})
      } catch (error) {}
    }
    const filtros_value = ref([])
    const cabeceras_value = ref([])

    const CargarEditar = async item => {
      console.log('CargarEditar', item)
      if (item > 0) {
        await cargarCategorias(item)
      } else {
        datos.value = JSON.parse(JSON.stringify(datosInit))
      }
      //datos.value = item
      cabeceras_value.value = JSON.parse(datos.value.cabeceras)
      filtros_value.value = JSON.parse(datos.value.filtros)
    }

    const abrirCabeceras = dato => {
      dialogCabecera.value = true

      if (dato) {
        cabecera.value = JSON.parse(
          JSON.stringify({
            ...dato,
            format: JSON.stringify(dato.format),
          }),
        )
      } else {
        cabecera.value = {
          key: generarIdUnico(),
          nuevo: true,
          text: '',
          value: '',
          order: 0,
          format: JSON.stringify({
            small: true,
          }),
        }
      }
    }
    const abrirFiltros = dato => {
      dialogFiltros.value = true
      if (dato) {
        filtro.value = JSON.parse(JSON.stringify(dato))
      } else {
        filtro.value = {
          key: generarIdUnico(),
          nuevo: true,
          id: '',
          tipo: '',
          value: '',
          default: '',
          add: false,
          dense: true,
          clearable: true,
          label: '',
          rules: '[]',
          disabled: false,
          filtros: '',
          filtrosValue: null,
          icon: false,
          outlined: true,
          order: 1,
        }
      }
    }
    const guardado = ref(false)
    const guardar = () => {
      if (validarForm() && cabeceras_value.value.length > 0) {
        guardado.value = true
        datos.value
        var _datos = JSON.parse(JSON.stringify(datos.value))
        _datos.filtros = JSON.stringify(filtros_value.value)
        _datos.cabeceras = JSON.stringify(cabeceras_value.value)

        _datos.querys = _datos.querys.replace(/'/g, "''")

        MaestroService.reportesActualizar(_datos)
          .then(response => {
            alert('guardando')
          })
          .catch(error => {})
          .finally(() => {
            guardado.value = false
          })
      }
    }

    const formCabecera = ref(null)
    const formFiltro = ref(null)
    const form = ref(null)
    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }

    const validarFormCabecera = () => {
      let val = formCabecera.value?.validate()
      return val
    }
    const validarFormFiltro = () => {
      let val = formFiltro.value?.validate()
      return val
    }
    const guardarCabecera = () => {
      if (validarFormCabecera()) {
        const dato = cabecera.value
        var valores = JSON.parse(JSON.stringify(cabeceras_value.value))
        if (dato.nuevo) {
          const valo = valores.find(it => it.key == dato.key)
          if (valo) {
            alert('ya existe uno con el mismo id')
          } else {
            delete dato['nuevo']
            valores.push({
              ...dato,
              format: JSON.parse(dato.format),
            })
          }
        } else {
          valores = valores.filter(it => it.key != dato.key)
          valores.push({
            ...dato,
            format: JSON.parse(dato.format),
          })
        }
        console.log(valores)
        

        console.log(valores)

        cabeceras_value.value = JSON.parse(JSON.stringify(valores))
        dialogCabecera.value = false
      }
      //formCabecera.value.resetValidation()
    }
    const guardarFiltros = () => {
      if (validarFormFiltro()) {
        const dato = filtro.value
        var valores = JSON.parse(JSON.stringify(filtros_value.value))
        if (dato.nuevo) {
          const valo = valores.find(it => it.id == dato.id)
          if (valo) {
            alert('ya existe uno con el mismo id')
          } else {
            delete dato['nuevo']
            valores.push(dato)
          }
        } else {
          valores = valores.filter(it => it.id != dato.id)
          valores.push(dato)
        }
        console.log(valores)
        valores.sort(function (a, b) {
          if (Number(a.order) > Number(b.order)) {
            return 1
          }
          if (Number(a.order) < Number(b.order)) {
            return -1
          }
          // a must be equal to b
          return 0
        })

        console.log(valores)

        filtros_value.value = JSON.parse(JSON.stringify(valores))
        dialogFiltros.value = false
      }
      // formFiltro.value.resetValidation()
    }

    return {
      tablasSelects,
      IrALista,
      limpiar,
      datos,
      cargarTablas,
      tablas,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
        mdiArrowUpThick,
        mdiEqual,
        mdiArrowDownThick,
        mdiCached,
        mdiPackageVariantClosed,
        mdiArrowLeftThick,
        mdiPlus,
      },
      CargarEditar,
      text: '',
      menu: false,
      menuX: 0,
      menuY: 0,
      options: ['producto', 'puesto', 'comanda'],
      filteredOptions: [],
      selectedItem: null,
      categorias,
      dialog,
      iconos,
      seleccionarIcon,
      dialogCabecera,
      dialogFiltros,
      filtro,
      cabecera,
      abrirCabeceras,
      abrirFiltros,
      tiposFiltros,
      guardarCabecera,
      guardarFiltros,
      formCabecera,
      formFiltro,
      reglas,
      filtros_value,
      cabeceras_value,
      form,
      validarForm,
      guardar,
      eliminarCabeceras,
      eliminarFiltros,
    }
  },
}
</script>