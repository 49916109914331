<template>
  <v-row>
    <v-col cols="12" v-show="vista == 1">
      <actualizar @IrALista="IrALista" ref="ActualizarRef" @GetActualizarLista="GetActualizarLista"></actualizar>
    </v-col>
    <v-col cols="12" v-show="vista == 0">
      <listar ref="ListarRef" @GetEditar="GetEditar"></listar>
    </v-col>
  </v-row>
</template>
     
    <script>
import Listar from './componentes/Lista.vue'
import Actualizar from './componentes/Actualizar.vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
export default {
  mounted() {
    if (this.$route.params.vista != undefined && this.$route.params.vista != null )  {
      if (this.$route.params.vista >= 0) {
        this.vista = 1
        this.ActualizarRef.CargarEditar(this.$route.params.vista)
      } else {
        this.vista = 0
        this.ListarRef.cargar()
      }
    } else {
      this.vista = 0
      this.ListarRef.cargar()
    }
  },
  watch: {
    '$route.params.vista': function () {
        
      if (this.$route.params.vista != undefined && this.$route.params.vista != null ) {
        
        if (this.$route.params.vista >= 0) {
          this.vista = 1
          this.ActualizarRef.CargarEditar(this.$route.params.vista)
        } else {
          this.vista = 0
          this.ListarRef.cargar()
        }
      } else {
        this.vista = 0
        this.ListarRef.cargar()
      }
    },
  },
  components: {
    Listar,
    Actualizar,
  },
  setup() {
    const ListarRef = ref(null)
    const ActualizarRef = ref(null)
    const vista = ref(0)
    const datos = ref({
      id: -1,
      descripcion: '',
      indActivo: true,
    })

    const GetActualizarLista = item => {
      vista.value = 0
      ListarRef.value.cargar(item)
    }
    const GetEditar = item => {
      console.log('GetEditar', item)
      router.push({ name: 'administracion-archivo-reportes', params: { vista: item.id } })
    }

    const IrALista = () => {
      router.push({ name: 'administracion-archivo-reportes' })
    }

    const limpiar = () => {
      vista.value = 1
      ActualizarRef.value.cargarTablas()
      ActualizarRef.value.limpiar()
    }
    

    return {
      ListarRef,
      ActualizarRef,
      datos,
      GetActualizarLista,
      GetEditar,
      vista,
      limpiar,
      IrALista,
    }
  },
}
</script>
     